import React, { Component } from "react";
import styled from "styled-components";
import { OpenInNew as BaseOpenInNew } from "@material-ui/icons";
import { Masonry } from "masonic";
import Gallery from "./Gallery";
import { IconButton, iconStyles } from "./IconButton";

const OpenInNew = styled(BaseOpenInNew)`
  ${iconStyles}
`;
const GithubContainer = styled.span`
  ${iconStyles}
  &:before {
    font-size: 3rem;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;
const MasonryCard = ({ data: item }) => (
  <div key={item.name} className="columns portfolio-item">
    <div className="item-wrap">
      <img src={`${item.imgurl}`} alt={item.description} className="item-img" />
      <div className="overlay">
        <div className="portfolio-item-meta">
          <h5>{item.name}</h5>
          <p>{item.description}</p>
          <IconContainer>
            {item.github && (
              <IconButton href={item.github}>
                <GithubContainer className="fa fa-github" />
              </IconButton>
            )}
            {item.url && (
              <IconButton href={item.url}>
                <OpenInNew />
              </IconButton>
            )}
            <Gallery gallery={item.gallery} />
          </IconContainer>
        </div>
      </div>
    </div>
  </div>
);

export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve collapsed">
            <h1>Check Out Some of the projects I've worked on.</h1>
            <div id="portfolio-wrapper" className="cf">
              <Masonry
                columnWidth={400}
                items={resumeData.portfolio}
                render={MasonryCard}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
