import React, { Component } from "react";
import { getRelativeYears } from "../utils";
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about-content">
        <div id="about"></div>
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src="images/profilepic.jpg" alt="" />
          </div>

          <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>
              {typeof resumeData.aboutme === "function"
                ? resumeData.aboutme(getRelativeYears(resumeData.startDate))
                : resumeData.aboutme}
            </p>

            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{resumeData.name}</span>
                  <br></br>
                  <a href={`mailto:${resumeData.address}`} className="email">
                    {resumeData.address}
                  </a>
                  <br></br>
                  <span>{resumeData.website}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
