import React, { Fragment } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { loadImage, loadSrc } from "../../utils";

function SuspenseSource({ src, alt, ...props }) {
  loadSrc(src).read();
  return <source {...props} src={src} alt={alt} />;
}

const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: inherit;
`;

function SuspenseImage({ src, alt, ...props }) {
  loadImage(src).read();
  return <img {...props} src={src} alt={alt} />;
}

const Img = styled(SuspenseImage)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: inherit;
  margin-bottom: -20px;
  padding-bottom: 20px;
`;

const CaptionDiv = styled.div`
  line-height: 1.75rem;
  text-align: center;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
`;

const CaptionType = styled(Typography)`
  && {
    color: #eee;
    font-size: 1rem;
    line-height: 1rem;
  }
`;

// Saving rendered JSX to help elements load quickly
export const GalleryCache = {};

export function GalleryItem({ src, alt, style, poster }) {
  if (!GalleryCache[src]) {
    GalleryCache[src] = (
      <Fragment>
        {src.startsWith("video") ? (
          <Video
            key={alt}
            style={style}
            poster={poster}
            autoPlay
            loop
            muted
            playsinline
          >
            <SuspenseSource src={src} alt={alt} />
          </Video>
        ) : (
          <Img key={alt} style={style} src={src} alt={alt} />
        )}
        <CaptionDiv>
          <CaptionType variant="overline">{alt}</CaptionType>
        </CaptionDiv>
      </Fragment>
    );
  }
  return GalleryCache[src];
}

export default GalleryItem;
