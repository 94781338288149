import React, { useEffect, useState } from "react";

const initialState = { header: 0, footer: 0 };

export const HeightContext = React.createContext(initialState);

export default function HeightContextProvider({
  children,
  headerRef,
  footerRef,
}) {
  const [heights, setHeights] = useState(initialState);

  useEffect(() => {
    if (
      footerRef.current instanceof Element &&
      footerRef.current.clientHeight !== heights.footer
    ) {
      setHeights((prevHeights) => ({
        ...prevHeights,
        footer: footerRef.current.clientHeight,
      }));
    }
  }, [footerRef, heights.footer, setHeights]);
  useEffect(() => {
    if (
      headerRef.current instanceof Element &&
      headerRef.current.clientHeight !== heights.header
    ) {
      setHeights((prevHeights) => ({
        ...prevHeights,
        header: headerRef.current.clientHeight,
      }));
    }
  }, [headerRef, heights.header, setHeights]);

  return (
    <HeightContext.Provider value={heights}>{children}</HeightContext.Provider>
  );
}
