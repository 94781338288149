import styled from "styled-components";
import { IconButton as BaseIconButton } from "@material-ui/core";

export const IconButton = styled(BaseIconButton)`
  &&& {
    display: inline-flex;
    color: ${({ theme }) => theme.palette.common.white};
  }
  &&:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const iconStyles = `
  && {
    width: 2em;
    height: 2em;
  }
`;
