// borrowed from https://sergiodxa.com/articles/react/suspense-image-loading
/* eslint-disable default-case */
function createResource(asyncFn) {
  // we start defining our resource is on a pending status
  let status = "pending";
  // and we create a variable to store the result
  let result;
  // then we immediately start running the `asyncFn` function
  // and we store the resulting promise
  const promise = asyncFn().then(
    (r) => {
      // once it's fulfilled we change the status to success
      // and we save the returned value as result
      status = "success";
      result = r;
    },
    (e) => {
      // once it's rejected we change the status to error
      // and we save the returned error as result
      status = "error";
      result = e;
    }
  );
  // lately we return an error object with the read method
  return {
    read() {
      // here we will check the status value
      switch (status) {
        case "pending":
          // if it's still pending we throw the promise
          // throwing a promise is how Suspense know our component is not ready
          throw promise;
        case "error":
          // if it's error we throw the error
          throw result;
        case "success":
          // if it's success we return the result
          return result;
      }
    },
  };
}

// First we need a type of cache to avoid creating resources for images
// we have already fetched in the past
const cache = new Map();

// then we create our loadImage function, this function receives the source
// of the image and returns a resource
export function loadImage(source) {
  // here we start getting the resource from the cache
  let resource = cache.get(source);
  // and if it's there we return it immediately
  if (resource) return resource;
  // but if it's not we create a new resource
  resource = createResource(
    () =>
      // in our async function we create a promise
      new Promise((resolve, reject) => {
        // then create a new image element
        const img = new window.Image();
        // set the src to our source
        img.src = source;
        // and start listening for the load event to resolve the promise
        img.addEventListener("load", () => resolve(source));
        // and also the error event to reject the promise
        img.addEventListener("error", () =>
          reject(new Error(`Failed to load image ${source}`))
        );
      })
  );
  // before finishing we save the new resource in the cache
  cache.set(source, resource);
  // and return return it
  return resource;
}

export function loadSrc(source) {
  // here we start getting the resource from the cache
  let resource = cache.get(source);
  // and if it's there we return it immediately
  if (resource) return resource;
  // but if it's not we create a new resource
  resource = createResource(
    () =>
      // in our async function we create a promise
      new Promise((resolve, reject) => {
        // then create a new image element
        const srcEl = document.createElement("video");
        // set the src to our source
        srcEl.src = source;
        // and start listening for the load event to resolve the promise
        srcEl.addEventListener("canplay", () => {
          return resolve(source);
        });
        // and also the error event to reject the promise
        srcEl.addEventListener("error", () =>
          reject(new Error(`Failed to load image ${source}`))
        );
      })
  );
  // before finishing we save the new resource in the cache
  cache.set(source, resource);
  // and return return it
  return resource;
}

const roundQuarter = (num) => Math.round(num * 4) / 4;

const mapToRelative = (years) => ({
  0: `${years} years`,
  0.25: `just over ${years} years`,
  0.5: `${years} and a half years`,
  0.75: `nearly ${years} years`,
  1: `${years} years`,
});

export const getRelativeYears = (start) => {
  const today = new Date();
  let diff = (today.getTime() - start.getTime()) / 1000;
  diff = diff / (60 * 60 * 24) / 365.25;

  const remainder = roundQuarter(diff - Math.abs(Math.floor(diff)));
  const rounded = Math.abs(
    remainder <= 0.5 ? Math.floor(diff) : Math.ceil(diff)
  );

  return mapToRelative(rounded)[roundQuarter(remainder)];
};
