import React, { useRef } from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import HeightContextProvider from "./components/HeightContext";
import Header from "./components/Header";
import About from "./components/About";
import Resume from "./components/Resume";
import Portfolio from "./components/Portfolio";
import Testimonials from "./components/Testimonials";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import resumeData from "./resumeData";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#11ABB0" },
    secondary: { main: "#F06000" },
  },
});
function App() {
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <div className="App">
          <HeightContextProvider headerRef={headerRef} footerRef={footerRef}>
            <Header ref={headerRef} resumeData={resumeData} />
            <About resumeData={resumeData} />
            <Resume resumeData={resumeData} />
            <Portfolio resumeData={resumeData} />
            <Testimonials resumeData={resumeData} />
            <ContactUs footerRef={footerRef} resumeData={resumeData} />
            <Footer ref={footerRef} resumeData={resumeData} />
          </HeightContextProvider>
        </div>
      </StyledThemeProvider>
    </ThemeProvider>
  );
}

export default App;
