import React, { useContext } from "react";
import { HeightContext } from "./HeightContext";
import styled from "styled-components";

const Section = styled.section`
  height: calc(100vh - ${(props) => props["nav-heights"]}px);
`;

const I = styled.i`
  letter-spacing: 1.35rem;
`;

const setAdjust = (header, footer) =>
  // Subtracting one, otherwise there's sliver of the previous section
  Math.floor(header) + Math.floor(footer) - 1;

export default function ContactUs({ resumeData }) {
  const { header, footer } = useContext(HeightContext);

  return (
    <Section id="contact" nav-heights={setAdjust(header, footer)}>
      <div className="row section-head">
        <div className="ten columns">
          <h2>Contact me.</h2>
          <span className="amazing-color">
            Questions? Work? Suggestions? Find me here.
          </span>
        </div>
      </div>
      <div className="row">
        <aside className="eigth columns footer-widgets">
          <div className="widget">
            <h1>
              <a href={`mailto:${resumeData.address}`} className="email">
                <i className="fa fa-envelope"></i> {resumeData.address}
              </a>
            </h1>
            <ul>
              {resumeData.socialLinks.map((item) => (
                <li key={item.name}>
                  <a target="_blank" rel="noopener noreferrer" href={item.url}>
                    <I className={item.className}></I>{" "}
                    {item.url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </aside>
      </div>
    </Section>
  );
}
