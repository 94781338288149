import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { PhotoLibrary as BasePhotoLibrary } from "@material-ui/icons";
import { IconButton, iconStyles } from "../IconButton";

import DialogContent from "./DialogContent";

const PhotoLibrary = styled(BasePhotoLibrary)`
  ${iconStyles}
`;

export const Gallery = ({ gallery }) => {
  const [open, setOpen] = useState(false);
  const carouselRef = useRef(null);
  const handleClose = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open, setOpen]);
  const handleOpen = useCallback(() => {
    if (!open) {
      setOpen(true);
    }
  }, [open, setOpen]);
  const handleKeyDown = useCallback(
    (e) => {
      if (carouselRef.current) {
        switch (e.key) {
          case "ArrowLeft":
            carouselRef.current.prev();
            break;
          case "ArrowRight":
            carouselRef.current.next();
            break;
          default:
            break;
        }
      }
    },
    [carouselRef]
  );

  if (!Array.isArray(gallery)) {
    return null;
  }
  return (
    <React.Fragment>
      <IconButton aria-label="gallery" onClick={handleOpen}>
        <PhotoLibrary />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="md"
        onKeyDown={handleKeyDown}
        open={open}
        onBackdropClick={handleClose}
      >
        <DialogContent carouselRef={carouselRef} gallery={gallery} />
      </Dialog>
    </React.Fragment>
  );
};

export default Gallery;
