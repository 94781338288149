import React, { Fragment, Suspense, useCallback, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import BaseCarousel from "react-material-ui-carousel";
import GalleryItem, { GalleryCache } from "./GalleryItem";

/** Requires explicit overflow-y: scroll on body at page load */
const scrollWidth = window.innerWidth - document.body.clientWidth;

const GlobalStyle = createGlobalStyle`
  #root nav, #root section {
    filter: blur(1px);
    width: calc(100vw - ${scrollWidth}px);
  }
  #root nav {
    right: unset;
  }
  #root section:not(#testimonials) {
    /* margin-bottom: -2px;
    padding-top: 94px; */
  }
`;

const Carousel = styled(BaseCarousel)`
  & .CarouselItem > div {
    max-height: 80vh;
    display: grid;
    grid-auto-rows: calc(100% - 20px) 20px;
  }
  & .CarouselItem {
    background: black;
  }
  & > div[class^="Carousel-indicators"] {
    position: relative;
    z-index: 1;
    margin-top: 0;
    padding-top: 5px;
    background: #eee;
  }
`;

const FbDiv = styled.div`
  max-height: 80vh;
  opacity: 0;
`;

const DialogFallback = ({ gallery, i }) => {
  const current = gallery[i];
  if (current && GalleryCache[current.src]) {
    return (
      <FbDiv>
        <Suspense fallback={<div />}>
          <GalleryItem
            src={current.src}
            alt={current.alt}
            style={current.style}
            poster={current.poster}
          />
        </Suspense>
      </FbDiv>
    );
  }
  return <div />;
};

export function DialogContent({ gallery, carouselRef }) {
  const [fallbackIndex, setFbIndex] = useState(0);
  const handleChange = useCallback(
    (_curr, index) => {
      if (index !== fallbackIndex) {
        setFbIndex(index);
      }
    },
    [fallbackIndex, setFbIndex]
  );
  if (gallery.length === 1) {
    const { src, alt, style, poster } = gallery[0];
    return (
      <Suspense fallback={<div />}>
        <GalleryItem src={src} alt={alt} style={style} poster={poster} />
      </Suspense>
    );
  }
  return (
    <Fragment>
      <GlobalStyle />
      <Carousel ref={carouselRef} interval={5000} onChange={handleChange}>
        {gallery.map(({ src, alt, style, poster }) => (
          <Suspense
            key={alt}
            fallback={<DialogFallback gallery={gallery} i={fallbackIndex} />}
          >
            <GalleryItem src={src} alt={alt} style={style} poster={poster} />
          </Suspense>
        ))}
      </Carousel>
    </Fragment>
  );
}

export default DialogContent;
