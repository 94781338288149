const resumeData = {
  imagebaseurl: "",
  name: "Robert Aird",
  role: "Full Stack Developer",
  roleDescription:
    "I am a Software Engineer with a passion for creating maintainable, re-usable, DRY code backed by testing and documentation. I specialize in full stack JavaScript and TypeScript-based development, and working with both NoSQL and SQL databases.",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/robert-aird/",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "https://github.com/robertaird",
      className: "fa fa-github",
    },
  ],
  startDate: new Date(2018, 1, 1),
  aboutme: (years) =>
    `I am a Full Stack Developer with ${years} of professional experience and a background in design. I'm experienced working in fast-paced environments where I have been exposed to a wide array of tech. I enjoy discovering and sharing new technologies and techniques, and am constantly working to refine my skill set.`,
  address: "robkaird@gmail.com",
  website: "(248) 736-7114",
  education: [
    {
      UniversityName: "Operation Spark",
      specialization: "Hack Reactor",
      MonthOfPassing: "Dec",
      YearOfPassing: "2017",
    },
    {
      UniversityName: "College for Creative Studies",
      specialization: "B.F.A.",
      MonthOfPassing: "Dec",
      YearOfPassing: "2008",
    },
  ],
  work: [
    {
      CompanyName: "Forager",
      specialization: "Full Stack Developer",
      MonthOfLeaving: "Sep 2020 - ",
      YearOfLeaving: "June 2021",
      Achievements:
        "React/Node/PostgreSQL developer at a start-up building software for freight and logistics in JavaScript and TypeScript. Responsibilities included developing new features to help better understand the needs of carriers and shippers, data design, and breaking ground on an application to allow freight carriers to participate directly in the load marketplace.",
    },
    {
      CompanyName: "mumms Software",
      specialization: "Software Consultant",
      MonthOfLeaving: "Sep 2020, Feb - ",
      YearOfLeaving: "March 2021",
      Achievements:
        "Consulted on iOS development and app store distribution, third-party library integration, CSS media queries and selector specificity, and troubleshooting React development issues surrounding usage of hooks and context.",
    },
    {
      CompanyName: "mumms Software",
      specialization: "Full Stack Developer",
      MonthOfLeaving: "Feb 2018 - ",
      YearOfLeaving: "Aug 2020",
      Achievements:
        "Responsibilities included integration of new features, bug fixes, investigating new tools and technology, and the development of multiple MVP projects seen through to production. Implemented a modern, flexible development environment, which helped standardize code practices across the development teams. Assisted in interviewing, vetting, and onboarding of new team members.",
    },
  ],
  skillsDescription: "",
  skills: [
    {
      skillname: "JavaScript",
    },
    {
      skillname: "React",
    },
    {
      skillname: "TypeScript",
    },
    {
      skillname: "Node",
    },
    {
      skillname: "SQL",
    },
    {
      skillname: "Linux",
    },
    {
      skillname: "Bash",
    },
  ],
  portfolio: [
    {
      name: "GitHub User Search",
      description:
        "A GitHub user search application built in TypeScript and React. This project was essentially an excuse to experiment and play around with GraphQL and React's upcoming concurrent API.",
      imgurl: "images/portfolio/github-user-search.png",
      url: `${window.location.origin}/github-search/`,
      github: `https://github.com/robertaird/github-user-search`,
    },
    {
      name: "Certification App",
      description:
        "A React micro-app that allows physicians to certify and re-certify patients for hospice care. I expanded the initial MVP into a production ready application, designed the patient card UI, added unit testing and integrated features such as the ability for nurses to fill in hard copy certifications on behalf of a patient's physician.",
      imgurl: "images/portfolio/certappcert.png",
      gallery: [
        {
          src: "video/certapp.mp4",
          poster: "video/certapp_poster.jpg",
          alt: "Filling in a certification",
          style: {
            marginTop: "-8.2%",
            marginLeft: "-5.7%",
            width: "111.2%",
          },
        },
        {
          src: "images/portfolio/certappios.png",
          alt: "iOS app",
        },
        {
          src: "images/portfolio/certappnurse.png",
          alt: "Nurse view",
        },
        {
          src: "images/portfolio/certappattd.png",
          alt: "Attending Card",
        },
      ],
    },
    {
      name: "@mummsSoftware/common-ui",
      description:
        "Common React components for mumms applications, published to a private npm registry. Built with @material-ui and styled-components, with a standalone dev environment and documentation generated by React-Styleguidist, as well as 100% Jest test coverage using @testing-library for functionality testing. The original components were repurposed from the Certification App, and the library grew from there.",
      imgurl: "images/portfolio/commonui01.png",
      gallery: [
        {
          src: "video/commonui.mp4",
          poster: "video/commonui_poster.jpg",
          alt: "Theme component",
        },
        {
          src: "images/portfolio/commonui01.png",
          alt: "Agency switcher and props",
        },
        {
          src: "images/portfolio/commonui02.png",
          alt: "Loader with example code and variants",
        },
      ],
    },
    {
      name: "Chart App",
      description:
        "Legacy application for display of patient history. I contributed bug fixes, re-worked and simplified the call flow, integrated the Certification App React component, and increased performance ranging from 13x to 86x faster depending on patient size.",
      imgurl: "images/portfolio/chart01.png",
      gallery: [
        {
          src: "images/portfolio/chartcerthistory.png",
          alt: "Certification component within Chart App",
        },
        {
          src: "images/portfolio/chart02.png",
          alt: "Chart app",
        },
      ],
    },
    {
      name: "C2",
      description:
        "A mobile version of Hummingbird Clinical, utilizing many of the same technologies. I contributed bug fixes and new features throughout the full stack, including the ability to bulk edit and discontinue medications, a summary of notable changes to the patient's chart within a time frame, and allowing the user to close and review multiple patient visits.",
      imgurl: "images/portfolio/c2patientchart.png",
      gallery: [
        {
          src: "images/portfolio/c2bulkmed.png",
          alt: "Bulk medications actions",
        },
        {
          src: "images/portfolio/c2patientchanges.png",
          alt: "Patient Changes",
        },
      ],
    },
    {
      name: "Hummingbird Clinical",
      description:
        "A complete EMR (Electronic Medical Record) system for the hospice industry. Legacy application. The frontend consists of a 25,000 line JavaScript/jQuery application that transforms an XML data store to XHTML via XSLT Transforms. The backend is a collection of Perl, Bash, PSQL, XML, and XSLT comprising 30,000 lines of code. I was responsible for fixing bugs and performance improvements.",
      imgurl: "images/portfolio/fierfoxpatientlist.png",
    },
  ],
  testimonials: [
    {
      description:
        "[Robert] continuously strives to share his wealth of knowledge with everybody on the team. This week, he took me to school on how to use a tool to measure performance of our primary development library, React.\u00A0.\u00A0.\u00A0. Robert’s intense passion for perfection in front-end development continues to help the entire engineering team be better, and I very much appreciate him for it.",
      name: "Ivan McCarthy, Senior Software Engineer, Forager",
    },
    {
      description:
        "I don't think we've ever had an HBC/M engineer come up to speed as quickly as you have. Your work ethic is terrific. The quality is excellent. And your thoroughness borders on unbelievable. Keep up the good work.",
      name: "Steve Turoff, CTO, mumms Software",
    },
    {
      description:
        "Unique for someone so early in his software engineering career, Robert practices a (sic) intense ownership and stewardship practices. This translates into his level of accountability. [He] will venture outside of his comfort zone and his skill set zone to design solutions independently. This leads to what appears to be a fearless embrace of new challenges.",
      name: "Jeff Ivey, Director of Engineering, mumms Software",
    },
  ],
};

export default resumeData;
